import React from "react"
import { Link, graphql } from "gatsby"
import "../styles/templates/news-list.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default ({ data, pageContext, location }) => {

    const { currentPage, numPages } = pageContext
    const posts = data.allMarkdownRemark.edges

    const firstPage = `/news`
    const secondPage = `/news/2`
    const lastPage = `/news/${numPages}`

    return (
        <Layout location={location} cssPageName="news-list">
            <SEO title={`News - Page ${currentPage}`} />
            <h1 className="title">News</h1>
            <nav className="news-list-nav">
                {location.pathname !== firstPage && location.pathname !== secondPage && <Link className=" nav-link newer" to={`/news/${currentPage - 1}`}>Newer</Link>}
                {location.pathname === secondPage && <Link className=" nav-link newer" to={`/news`}>Newer</Link>}
                <p className="current-page">Page {currentPage} of {numPages}</p>
                {location.pathname !== lastPage && numPages !== 1 && <Link className=" nav-link older" to={`/news/${currentPage + 1}`}>Older</Link>}
            </nav>
            <section className="list-body">
                {posts.map(({ node }) => (
                    <article className="news-post">
                        <div className="flex">
                            <Link to={`/news${node.fields.slug}`}><div className="article-image" style={{ backgroundImage: `url("${node.frontmatter.featuredImage}")` }} /></Link>
                            <div className="text-container">
                                <h2 className="article-title">{node.frontmatter.title}</h2>
                                <p className="article-description">{node.frontmatter.description}</p>
                            </div>
                        </div>
                        <Link className="read-link" to={`/news${node.fields.slug}`}>Read</Link>
                    </article>
                ))}
            </section>
            <nav className="news-list-nav">
                {location.pathname !== firstPage && location.pathname !== secondPage && <Link className=" nav-link newer" to={`/news/${currentPage - 1}`}>Newer</Link>}
                {location.pathname === secondPage && <Link className=" nav-link newer" to={`/news`}>Newer</Link>}
                <p className="current-page">Page {currentPage} of {numPages}</p>
                {location.pathname !== lastPage && numPages !== 1 && <Link className=" nav-link older" to={`/news/${currentPage + 1}`}>Older</Link>}
            </nav>
        </Layout >
    )
}

export const pageQuery = graphql`
query NewsPaginationQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(skip: $skip, limit: $limit, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
            fields {
              slug
            }
            frontmatter {
              title
              description
              featuredImage
              date
            }
        }
      }
    }
  }
`